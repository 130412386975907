import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Badge = styled.span`
    margin-left: 10px;
    width: 25px;
    background: #949ea7;
    display: inline-block;
    padding: 3px;
    font-size: 10px;
    color: #ececec;
    border-radius: 10px;
    position: absolute;
    right: 10px;
    text-align: center;
`

const Button = styled.button`
  margin-bottom: 15px;
  margin-right: 5px;
  padding: 10px;
  background-color: ${props => props.selected ? "#ffce4b" : "#fff"};
  opacity: ${props => props.selected ? "1" : "0.85"};
  color: ${props => props.selected ? "#222" : "#949ea7"};
  border: ${props => props.selected ? "2px solid #CCA53C" : "2px solid #949ea7"};
  cursor: pointer;
  font-size: 12px;
  outline: none;
  flex-basis: calc(20% - 10px);
  text-align: left;
  position: relative;
  transition: 0.15s;
  border-radius: 4px;
  text-transform: capitalize;

  &:hover {
    border-color: #ffce4b;
  }

  @media (max-width: 500px) {
    min-width: calc(50% - 5px);
    flex-basis: calc(50% - 5px);
  }
`

const {
  string,
  number,
  func,
  oneOfType
} = PropTypes

const FilterButton = props => {
  const selected = props.currentFilter === props.value ? true : false

  return (
    <Button onClick={() => props.onFilter(props.value)} selected={selected}>
      <span>{props.value}</span>
      <Badge>{props.secondaryText}</Badge>
    </Button>
  )
}

FilterButton.propTypes = {
  value: string,
  secondaryText: oneOfType([string, number]),
  onFilter: func,
  currentFilter: string
}

export default FilterButton
