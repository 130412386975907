import styled from 'styled-components'

export const PageHeading = styled.h1`
  text-align: center;
  padding-bottom: 9vh;
  margin-top: 5vh;
`

export const SectionHeading = styled.h2`
  text-align: center;
  margin-top: 1em;
`

export const Resources = styled.div`
  background-color: LightYellow;
  padding: 1vw 7vw 0;
`
export const Content = styled.div`
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
`

export const Main = styled.main`
padding: 4vh 7vw 0;
margin-top: -9vh;
background: #fff;
border-top: 3px solid #ffce4b;
`

export const Topics = styled.div`
display: flex; flex-wrap: wrap; justify-content: left; justify-content: space-between; padding-top: 2vh; @media(max-width: 768px){padding: 2vh 3vw;}`

export const Wrap = styled.div`
    background-color: ${props => props.primary ? "#ffce4b" : "#fff"};
    width: 100%;
    margin: 0;
    border: 1px solid #eee;
    border-color: ${props => props.primary ? "#ccc" : "#eee"};
    box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    transition: .15s;
    padding: 30px;
    border-radius: 4px;
`

export const Tag = styled.span`
  color: #222;
  text-transform: uppercase;
  border-radius: 4px;
  background-color: #ffce4b;
  font-size: 10px;
  margin-right: 10px;
  margin-bottom: 0;
  padding: 3px 6px;
  display: inline-block;
`

export const Meta = styled.div`
  color: #444;
  margin-top: 0.5em;
  font-size: 12px;
  font-weight: 200;
`

export const JumpTo = styled.div`
font-size:0.8em;
text-align: right;
padding: 0.5em 1em;
margin-left: 1em;
margin-bottom: 1em;
background: #eee;
display: inline-block;
float: right;
clear:both;
`