import React from 'react'
import { graphql } from 'gatsby'

import List from '../components/list'
import Layout from '../components/layout'
import Footer from '../components/footer'

import { PageHeading, Resources, Content } from '../styles'

export default ({ data: { posts } }) => (
  <Layout>
    <Resources>
      <Content>
        <PageHeading>All our Linked Resources</PageHeading>
        <List list={posts.edges.map(e => Object.assign({}, e.node.data, {tags: e.node.tags}))} showFilter />
      </Content>
    </Resources>
    <Footer />
  </Layout>
)

export const query = graphql`
  query {
    posts: allPrismicLink(sort: {fields: first_publication_date, order: DESC}) {
        edges {
          node {
            id
            tags
            data {
              title
              description
              type
              author
              publisher
              url
            }
          }
        }
      }
    topics: allMdx {
      edges {
        node {
          frontmatter {
            title
            tag
            subtitle
          }
        }
      }
    }
  }
`

