import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ListItem from './list-item'
import FilterButton from './filter-button'
import styled from 'styled-components'
import { ListOfItems } from '../styles/resource-item'

const Fake = styled.div`
  flex-basis: calc(20% - 10px);
`

const ButtonWrapper = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
padding: 15px 0 30px;
`

const ArrowWrapper = styled.div`
width: 100%;
background-color: LightYellow;
color: #949ea7;
z-index: 10;
position: absolute;
bottom: 0;
right: 0;
text-align: center;
padding-top: 10px;
cursor: pointer;
opacity: 0.95;
`

const Arrow = () => (
  <>
    <svg
      fill="#949ea7"
      className="button__container--icon"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
    >
      <polygon points="53.681,60.497 53.681,60.497 75.175,39.001 71.014,34.843 49.519,56.337 29.006,35.823 24.846,39.982   49.519,64.656 " />
    </svg>
  </>
)

const {
  arrayOf,
  object
} = PropTypes

const List = props => {

  const tagList = [
    ...new Set(
      props.list
        .filter(r => r.tags)
        .reduce((acc, cur) => [...acc, ...cur.tags], [])
    )
  ];

  const tagsNumberedSorted = tagList
      .map(t => ({
        title: t,
        count: props.list.filter(s => s.tags && s.tags.includes(t)).length
      }))
      .sort((a, b) => b.count - a.count);

  const [ collapsed, setCollapsed ] = useState(false)
  // const [ tagsNumberedSorted, setTagsNumberedSorted] = useState([])
  const [ tagFilter, setTagFilter ] = useState(false)

  const isCollapsed = collapsed ? "is-open" : "";
  const expanded = collapsed ? "Close filter" : "Expand filter";

  const list = props.list.filter(
    r =>
      !tagFilter ||
      (r.tags && r.tags.includes(tagFilter))
  ).map(resource => (
    <ListItem key={resource.id} {...resource} />
  ))

  return (
    <>
    {props.showFilter && (
    <div className={`button__container ${isCollapsed}`}>
              <ButtonWrapper>
                <ArrowWrapper onClick={() => setCollapsed(!collapsed)}>
                  {expanded}
                  <Arrow />
                </ArrowWrapper>
                <FilterButton
                  value={"All tags"}
                  key={`all-filter-button`}
                  onFilter={tagFilter => {
                    setTagFilter(false)
                    setCollapsed(false)
                  }}
                  currentFilter={
                    tagFilter ? tagFilter : "All tags"
                  }
                  secondaryText={props.list.length}
                />
                {tagsNumberedSorted.map(tag => (
                  <FilterButton
                    value={tag.title}
                    key={`${tag.title.replace(" ", "-")}-filter-button`}
                    onFilter={t => { setTagFilter(t); setCollapsed(false) }}
                    currentFilter={tagFilter}
                    secondaryText={tag.count}
                  />
                ))}
                <Fake />
                <Fake />
                <Fake />
                <Fake />
              </ButtonWrapper>
            </div>)}
    <ListOfItems>
      {list}
    </ListOfItems>
    <style jsx global>
          {`
            .button__container {
              margin-bottom: 50px;
              max-height: 150px;
              overflow: hidden;
              position: relative;
              transition: max-height 300ms cubic-bezier(0, 0.6, 0.6, 1);
            }
            .button__container.is-open {
              max-height: 999px;
              transition: max-height 400ms ease-in;
            }
            .button__container--icon {
              cursor: pointer;
              height: 24px;
              vertical-align: middle;
            }
            .button__container.is-open .button__container--icon {
              transform: rotate(180deg);
              transition: all 300ms ease-in 0s;
            }
          `}
        </style>
    </>
  )
}

List.propTypes = {
  list: arrayOf(object)
}

export default List
