import React from 'react'
import PropTypes from 'prop-types'
import { Tag, Meta } from '../styles'
import { Item } from '../styles/resource-item'
const {
  string,
  arrayOf
} = PropTypes

const types = {
  app: '📱',
  book: '📕',
  article: '📃',
  video: '🎞️',
}

const ListItem = props => {
  const { type, title, author = false, publisher = false, url, tags, description } = props

  const itemTags = tags ? tags.map((tag, index) => (
      <Tag key={`${tag}-${index}`}>{tag}</Tag>
  )) : []

  const itemMeta = (author, publisher, type) => {
    if (!author && !publisher) return null
    return (
      <Meta>{types[type]} {author}{ author && publisher  ? ', ' : ''}{publisher}</Meta>
    )
  }

  return (
    <Item>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <h3>{title}</h3>
        {itemMeta(author, publisher, type)}
        <p>{description}</p>
        {itemTags}
      </a>
    </Item>
  )
}

ListItem.propTypes = {
  title: string,
  author: string,
  publisher: string,
  html_url: string,
  tags: arrayOf(string)
}

export default ListItem
